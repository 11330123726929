import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/page-header"
import Container from "../../components/container"
import Button from "../../components/button"

export default function Tesekkurler() {
  return (
    <Layout>
      <SEO title="Teşekkürler" />
      <PageHeader title="Teşekkürler" />
      <Container>
        <div
          style={{
            paddingTop: "3rem",
            paddingBottom: "3rem",
            textAlign: "center",
          }}
        >
          <p>24 saat içinde mesajınıza geri dönüş yapılacaktır.</p>
          <Button to="/">Ana Sayfaya Geri Dön</Button>
        </div>
      </Container>
    </Layout>
  )
}
